// @flow
import api from './api';
import {
  hydrateUser,
  dehydrateUser,
  hydratePaymentMethod,
  dehydratePaymentMethod,
  User,
  PaymentMethod,
  MyAddress,
  MyPartner,
  dehydratePartner,
  UserConsent,
  dehydrateUserConsent,
  hydrateHomeInfo,
  hydrateMeOutreachs,
} from 'models/User';
import {
  processSetPaymentError,
  processUpdateProfileError,
  processSetAddressError,
  processSetMyParterError,
  processUpdateUserConsentError,
  processGrailGalleriConsent,
} from './transformers/errorTransformers';
import { hydrateAddresses, dehydrateAddresses } from './transformers/addressTransformers';
import { reduceEmailVerifyResponseData } from 'services/utils';

class UsersService {
  getMyProfile(): Promise<User> {
    return api.users.getMyProfile().then(hydrateUser);
  }

  updateMyProfile(user, token): Promise<any> {
    const accessToken: ?string = token ? token : api.getToken();
    return api.users.updateMyProfile(dehydrateUser(user), accessToken).catch((error) => {
      return processUpdateProfileError(error, user);
    });
  }

  updateConsent(user: UserConsent): Promise<any> {
    const accessToken: ?string = api.getToken();
    return api.users
      .updateMyProfile(dehydrateUserConsent(user), accessToken)
      .catch(processUpdateUserConsentError);
  }

  getMyPaymentMethod(): Promise<PaymentMethod> {
    return api.users.getMyPaymentMethod().then(hydratePaymentMethod);
  }

  updateMyPaymentMethod(paymentMethod: PaymentMethod): Promise<any> {
    const accessToken: ?string = api.getToken();
    return api.users
      .updateMyPaymentMethod(dehydratePaymentMethod(paymentMethod), accessToken)
      .catch(processSetPaymentError);
  }

  createMyAddress(myAddress: MyAddress): Promise<any> {
    const accessToken: ?string = api.getToken();
    return api.users
      .createMyAddress(dehydrateAddresses(myAddress), accessToken)
      .then(hydrateAddresses)
      .catch(processSetAddressError);
  }

  updateMyAddress(myAddress: MyAddress): Promise<any> {
    const accessToken: ?string = api.getToken();
    return api.users
      .updateMyAddress(dehydrateAddresses(myAddress), accessToken)
      .then(hydrateAddresses)
      .catch(processSetAddressError);
  }

  getMyAddress(): Promise<MyAddress> {
    return api.users.getMyAddress().then(hydrateAddresses);
  }

  updateMyPartner(myPartner: MyPartner): Promise<any> {
    const accessToken: ?string = api.getToken();
    return api.users
      .updateMyPartner(dehydratePartner(myPartner), accessToken)
      .catch(processSetMyParterError);
  }

  getHomeInfo(): Promise<any> {
    return api.users.getHomeInfo().then(hydrateHomeInfo);
  }

  getMeOutreach() {
    return api.users.getMeOutreach().then(hydrateMeOutreachs);
  }

  createTROEncounter(data) {
    return api.users.createTROEncounter(data).catch(processGrailGalleriConsent);
  }
  grailQuesionnaire(data) {
    return api.users.grailQuesionnaire(data);
  }

  updateConsentStatus(data): Promise<any> {
    return api.users.updateConsentStatus(data);
  }

  setupStripeIntent() {
    return api.users.setupStripeIntent();
  }

  setupStripeIntentFromEmail(data) {
    return api.users.setupStripeIntentFromEmail(data);
  }

  matchedPatient(email) {
    return api.users.matchedPatient(email);
  }

  createGeneTestOrder(params) {
    return api.users.createGeneTestOrder(params);
  }
  getUserInfo(uuid) {
    return api.users.getUserInfo(uuid);
  }
}

export default new UsersService();
