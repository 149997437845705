import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import authService from 'services/api/authService';
import usersService from 'services/api/usersService';
import SchedulingIntroPage from '../RegistrationWizardPage/SchedulingIntroPage';
import { setReferralPartnerName } from 'services/misc/ReferralSource';
import { closeLoader, showLoader } from 'ducks/ui';
import api from 'services/api/api';
import { ENABLE_AUTH0_SMS_LOGIN } from '../../../Auth0Wrapper';

const CompleteSetup = ({ history, match, dispatch }) => {
  const { params } = match;

  const [loading, setLoading] = useState(true);
  const query = new URLSearchParams(window.location.search);
  useEffect(() => {
    if (_.get(params, 'patientUUID')) {
      api.setPatientUUID(_.get(params, 'patientUUID'));
      getPartnerDetail();
    }
  }, []);

  const getPartnerDetail = async () => {
    try {
      dispatch(showLoader());
      const res = await usersService.getUserInfo(_.get(params, 'patientUUID'));
      if (res && res.partner && res.partner.name) {
        setReferralPartnerName(res.partner.name);
      }
    } finally {
      setLoading(false);
      dispatch(closeLoader());
    }
  };

  const handleNextStage = () => {
    if (ENABLE_AUTH0_SMS_LOGIN && query.get('source')) {
      authService.saveSource(query.get('source'));
    }
    authService.saveRedirectionAction('/scheduling/select-service');
    history.push('/auth0/login');
  };

  return (
    <>
      {loading ? (
        <div />
      ) : (
        <SchedulingIntroPage setActiveStage={handleNextStage} textToScheduleFlow={true} />
      )}
    </>
  );
};

export default connect(null, (dispatch) => ({
  dispatch: dispatch,
}))(CompleteSetup);
