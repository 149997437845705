import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import ButtonV2 from 'components/forms/controls/ButtonV2';
import InputField from 'components/forms/controls/V2/InputField';

import { required, email } from 'services/validate';
import './UpdateEmailForm.scss';

const validateEmailRequired = required('Email');

const UpdateEmailForm = (props) => {
  const { handleSubmit, submitting, valid } = props;

  return (
    <form className="updt-email-form" onSubmit={handleSubmit}>
      <div>
        <div className="label-field required">Email Address</div>
        <div className="input-section">
          <Field
            name="email"
            type="email"
            placeholder="Email Address"
            trim={true}
            component={InputField}
            validate={[validateEmailRequired, email]}
          />
        </div>
      </div>

      <ButtonV2 className="submit-button" type="submit" disabled={submitting || !valid}>
        Submit
      </ButtonV2>
    </form>
  );
};

UpdateEmailForm.prototypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
};

export default reduxForm({
  form: 'updateEmailForm',
})(UpdateEmailForm);
