import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import UpdateEmailForm from './UpdateEmailForm';
import { updateEmail } from 'ducks/profile';
import { AUTH0_CONFIG } from '../../../Auth0Wrapper';
import authService from 'services/api/authService';
import api from 'services/api/api';
import './UpdateYourRecord.scss';

const UpdateYourRecord = ({ dispatch, location, history }) => {
  const { getAccessTokenSilently } = useAuth0();

  const onSubmit = async (data) => {
    const at = await getAccessTokenSilently({
      authorizationParams: {
        audience: AUTH0_CONFIG.audience,
      },
    });
    const token = `Bearer ${at}`;
    return dispatch(updateEmail(data, token)).then(() => {
      const url = authService.getRedirectionAction() || '/patient/home';
      authService.resetRedirectionAction();
      api.setToken(token);
      history.push(url);
    });
  };

  return (
    <div className="updt-recrd-page">
      <div className="form-wrapper">
        <i className="genome-logo" />
        <span className="updt-recrd-title">Update Your Records</span>
        <div className="updt-recrd-desc">
          Please enter an email address below to complete your patient records
        </div>

        <UpdateEmailForm onSubmit={onSubmit} />
      </div>
    </div>
  );
};

UpdateYourRecord.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect()(withRouter(UpdateYourRecord));
