// @flow
import _ from 'lodash';
import moment from 'moment';
import { ConsentStatus } from '../constants/CommonConstants';
import { guardianPhones, patientPhones } from '../services/api/transformers/authTransformer';

export type Role = 'patient' | 'provider' | 'admin';
export type Gender = 'male' | 'female' | 'undefined';

export type User = {
  id: number,
  email: string,
  role: Role,
  confirmed: boolean,
  timezone?: string,
  phone?: string,
  profileImage?: string,
  firstName: string,
  lastName: string,
  fullName: string,
  portalUrl?: string,
  partnerId?: number,
  affiliation?: string,
};
export type UserConsent = {
  abnDate: string,
  abnSignature: string,
  consentToTreat: string,
};

export type PaymentMethod = {
  cardholderName: string,
  cardBrand: string,
  cardNumberLast4: string,
  cardNumber?: string,
  cardCVC?: string,
  cardExpirationMonth: number,
  cardExpirationYear: number,
  billingAddressLine1: string,
  billingAddressLine2: string,
  billingAddressZipCode: string,
  city: string,
  state: string,
};

export type MyAddress = {
  addressFullName: string,
  addressType: string,
  addressLine1: string,
  addressLine2: string,
  addressCity: string,
  addressState: string,
  addressZipCode: string,
};

export type PaymentStatus = {
  status: string,
  created: Date,
};

export type MyPartner = {
  switchToScp: boolean,
};

export function hydrateUser(data: Object): User {
  const phone =
    data && data.has_guardian
      ? _.get(data, ['users_guardian', 'phones', 'primary', 'value'], '')
      : _.get(data, ['phones', 'primary', 'value'], '');
  const affiliationIsGrailSeeker = data && data.affiliation && data.affiliation == 'Grail_Seeker';
  const affiliationIsNovartisGalleri =
    data && data.affiliation && data.affiliation == 'Novartis_Galleri';
  const affiliationIsIllumina = data && data.affiliation && data.affiliation == 'Illumina';
  return {
    id: data.id,
    email: data.email,
    role: data.role,
    confirmed: data.confirmed,
    hasPaymentMethod: data.has_payment_method,
    timezone: data.timezone,
    phone: phone,
    primaryPhone: data && data.phones && data.phones.primary && data.phones.primary.value,
    primaryPhoneType: data && data.phones && data.phones.primary && data.phones.primary.type,
    primaryPhoneConsentToText:
      data && data.phones && data.phones.primary && data.phones.primary.consent_to_text,
    secondaryPhone: data && data.phones && data.phones.secondary && data.phones.secondary.value,
    secondaryPhoneType: data && data.phones && data.phones.secondary && data.phones.secondary.type,
    dateOfBirth: data.dob,
    profileImage: data.profile_image,
    firstName: data.first_name,
    lastName: data.last_name,
    fullName: data.full_name,
    referral: data.referral_source,
    partnerId: data.partner_id,
    partnerUUID: data && data.partner && data.partner.uuid,
    affiliation: data.affiliation,
    is_payment_mode_insurance_only: data.insurance_only,
    vsee_specialty: data.vsee_specialty,
    gender: data.gender,
    ethnicity: data && data.ethnicity && data.ethnicity.display_name,
    race: data && data.race && data.race.display_name,
    secondaryRaces: data && data.secondary_races && hydrateSecondaryRaces(data.secondary_races),
    referral_provider: data.referral_provider && dehydrateRefreal(data.referral_provider),
    isSCP: data.is_scp,
    isMedicareBeneficiary: data.is_medicare_beneficiary,
    abnSignature: data.abn_signature,
    hasGuardian: data.has_guardian,
    isUnborn: data.is_unborn,
    usersGuardian: !_.isNil(data.users_guardian) ? hydrateUserGuardian(data.users_guardian) : null,
    isConsentPending: _.isNil(data.consent_to_treat) || data.consent_to_treat == 'not_consented',
    isABNConsentPending: data.is_medicare_beneficiary === true && _.isNil(data.abn_signature),
    welcomeUserName: data.is_unborn ? `${data.last_name} Family` : data.first_name,
    partnerSoftRegistrationRequired: data && data.partner && data.partner.soft_reg_required == true,
    pronouns: data && data.pronouns && hydratePronouns(data.pronouns),
    sexAssignedAtBirth: data && data.sex_assigned_at_birth,
    preferredName: data && data.preferred_name,
    partnerSpecialties: data && data.partner && orderingSpecialities(data.partner.specialties),
    isPhoneOnly: data && data.partner && data.partner.is_phone_only,
    exceptionalSpecialties: data && data.partner && data.partner.exceptional_specialties,
    grailStatus: data && data.grail_status,
    grailGalleriLabUUID: data && data.grail_galleri_lab_uuid,
    grailGalleriTestUUID: data && data.grail_galleri_test_uuid,
    grailGalleriOrderCreatedAt:
      data && data.grail_galleri_order_created_at
        ? moment(data.grail_galleri_order_created_at).format('MM/DD/YYYY')
        : null,
    consentForRecontactRegardingGalleri: data.consent_for_recontact_regarding_galleri,
    affiliationIsGrailSeeker: affiliationIsGrailSeeker,
    affiliationIsNovartisGalleri: affiliationIsNovartisGalleri,
    affiliationIsIllumina: affiliationIsIllumina,
    isGrailFlowAffiliation: affiliationIsGrailSeeker || affiliationIsNovartisGalleri,
    showInsuranceCardToPP: data && data.partner && data.partner.show_insurance_card_to_pp,
    partnerSchedulingPlatform: data && data.partner && data.partner.scheduling_platform,
    uuid: data && data.uuid,
    activeConsent: data && data.consents && hydrateConsent(data.consents),
    latestPatientToReconatctStatusObject:
      data && data.consents && getLatestPatientToReconatctStatusObject(data.consents),
    allowedCountries: data && data.partner && data.partner.allowed_countries,
    countryIsNonUS: data && data.partner && data.partner.allowed_countries && countryIsNonUS(data),
    preSelectedCountry:
      data &&
      data.partner &&
      data.partner.allowed_countries &&
      preSelectedCountry(data.partner.allowed_countries),
    requireShippingAddress: data && data.partner && data.partner.require_shipping_address,
  };
}

function preSelectedCountry(countries) {
  if (countries && countries.length > 0) {
    return countries[0];
  }
  return 'US';
}
function countryIsNonUS(data) {
  return data.partner.allowed_countries.find((a) => a != 'US');
}

function getLatestPatientToReconatctStatusObject(data) {
  let result = null;
  const _consent = _.find(data, (obj) => {
    return obj.type == 'patient_authorization_to_recontact';
  });
  if (!_consent) {
    return null;
  }

  const findStatus = (status) =>
    _consent.consent_versions.find((con) => con.user_consent && con.user_consent.status == status);

  if (findStatus(ConsentStatus.REVOKED)) {
    result = findStatus(ConsentStatus.REVOKED);
  } else if (findStatus(ConsentStatus.CONSENTED)) {
    result = findStatus(ConsentStatus.CONSENTED);
  } else if (findStatus(ConsentStatus.PROCESSING)) {
    result = findStatus(ConsentStatus.PROCESSING);
  } else if (findStatus(ConsentStatus.SKIPPED)) {
    result = findStatus(ConsentStatus.SKIPPED);
  }
  return result;
}

function hydrateConsent(data) {
  const _consent = _.find(data, (obj) => {
    return obj.type == 'patient_authorization_to_recontact';
  });
  if (_consent) {
    return {
      id: _consent.id,
      type: _consent.type,
      description: _consent.description,
      consentVersions: _consent.consent_versions,
      latestConsentVersion:
        _consent.consent_versions && hydrateConsentVersion(_consent.consent_versions),
    };
  }
  return null;
}

function hydrateConsentVersion(data) {
  const _version = _.find(data, (obj) => {
    return obj.active == true;
  });
  if (_version) {
    return {
      id: _version.id,
      version: _version.consent_version,
      formId: _version.form_id,
      active: _version.active,
      createdAt: _version.created_at,
      userConsent: _.isNil(_version.user_consent)
        ? null
        : hydrateUserConsent(_version.user_consent),
    };
  }
  return null;
}

function hydrateUserConsent(data) {
  return {
    status: data.status,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
  };
}

function hydrateUserGuardian(data: Object): Object {
  return {
    firstName: data.first_name,
    lastName: data.last_name,
    relationship: data.relationship,
    dob: data.dob,
    guardianPrimaryPhone: data && data.phones && data.phones.primary && data.phones.primary.value,
    guardianPrimaryPhoneType:
      data && data.phones && data.phones.primary && data.phones.primary.type,
    guardianPrimaryPhoneConsentToText:
      data && data.phones.primary && data.phones.primary.consent_to_text,
    guardianSecondaryPhone:
      data && data.phones && data.phones.secondary && data.phones.secondary.value,
    guardianSecondaryPhoneType:
      data && data.phones && data.phones.secondary && data.phones.secondary.type,
    guardianPronouns: data && data.pronouns && hydratePronouns(data.pronouns),
    guardianPreferredName: data && data.preferred_name,
  };
}

function orderingSpecialities(specialties: Array): Array {
  const customOrder = ['Other_Adult_Genetics', 'Healthy_Screening'];

  specialties.forEach((obj, index) => {
    obj.index = customOrder.indexOf(obj.name);
  });

  specialties.sort((a, b) => {
    return a.index - b.index;
  });
  return specialties;
}

function hydratePronouns(data: string): Object {
  const pronounsStr = data.slice(1, data.length - 1);
  const pronounsArr = pronounsStr.split(',');

  return pronounsArr.reduce(
    (acc, pronoun) => ({
      ...acc,
      [pronoun]: true,
    }),
    {}
  );
}

function dehydratePronouns(data: Object): string {
  const pronounsArr = Object.keys(data).filter((k) => data[k] === true);
  const pronounsStr = pronounsArr.join(',');

  return `{${pronounsStr}}`;
}

function hydrateSecondaryRaces(data: Array): Object {
  return data.reduce(
    (acc, raceObj) => ({
      ...acc,
      [raceObj.display_name]: true,
    }),
    {}
  );
}

function dehydrateSecondaryRaces(data: Object = {}): string {
  return Object.keys(data).filter((k) => data[k] === true);
}

function dehydrateRefreal(data: Object): Object {
  return {
    name: data.name,
  };
}
export function dehydrateUser(user: User): Object {
  return _.omitBy(
    user.hasGuardian
      ? {
          phone: user.phone,
          gender: user.gender,
          pronouns: user && user.pronouns && dehydratePronouns(user.pronouns),
          sex_assigned_at_birth:
            user && !_.isNil(user.sexAssignedAtBirth) && user.sexAssignedAtBirth != 'unknown'
              ? user.sexAssignedAtBirth
              : undefined,
          preferred_name: user && user.preferredName,
          race: user && user.race ? user.race : undefined,
          ethnicity: user && user.ethnicity ? user.ethnicity : undefined,
          secondary_races:
            user && user.secondaryRaces && dehydrateSecondaryRaces(user.secondaryRaces),
          users_guardian: {
            first_name: user && user.usersGuardian && user.usersGuardian.firstName,
            last_name: user && user.usersGuardian && user.usersGuardian.lastName,
            dob: user && user.guardianDob,
            relationship: user && user.usersGuardian && user.usersGuardian.relationship,
            phones: user && user.usersGuardian && guardianPhones(user),
            pronouns: user && user.guardianPronouns && dehydratePronouns(user.guardianPronouns),
            preferred_name: user && user.guardianPreferredName,
          },
          email: user && user.email,
        }
      : {
          phone: user && user.phone,
          phones: user && patientPhones(user),
          gender: user && user.gender,
          pronouns: user && user.pronouns && dehydratePronouns(user.pronouns),
          sex_assigned_at_birth:
            user && !_.isNil(user.sexAssignedAtBirth) && user.sexAssignedAtBirth != 'unknown'
              ? user.sexAssignedAtBirth
              : undefined,
          preferred_name: user && user.preferredName,
          race: user && user.race ? user.race : undefined,
          ethnicity: user && user.ethnicity ? user.ethnicity : undefined,
          secondary_races:
            user && user.secondaryRaces && dehydrateSecondaryRaces(user.secondaryRaces),
          email: user && user.email,
        },
    _.isUndefined
  );
}

export function dehydrateUserConsent(user: UserConsent): Object {
  return _.omitBy(
    {
      abn_date: user.abnDate,
      abn_signature: user.abnSignature,
      consent_to_treat: user.consentToTreat,
      consent_for_recontact_regarding_galleri: user.consentForRecontactRegardingGalleri,
    },
    _.isUndefined
  );
}

export function hydratePaymentMethod(data: Object): PaymentMethod {
  let obj = {
    cardholderName: data.name,
    cardBrand: data.brand,
    cardNumberLast4: data.last4,
    cardExpirationMonth: data.exp_month,
    cardExpirationYear: data.exp_year,
  };
  return obj;
}

export function dehydratePaymentMethod(paymentMethod: PaymentMethod): Object {
  let obj = {
    user_addresses_id: paymentMethod.addressId,
    setup_intent_id: paymentMethod.setupIntentId,
  };
  return _.omitBy(obj, _.isUndefined);
}

export function dehydratePartner(data: MyPartner): Object {
  if (data.switchToScp) return { switch_to_scp: data.switchToScp };
  else if (data.switchGrail) return { switch_grail: data.switchGrail };
  else if (data.switchNovartis) return { switch_novartis: data.switchNovartis };
}

export function hydrateHomeInfo(data) {
  return {
    consultation: (data.consultation && hydrateConsultations(data.consultation)) || [],
  };
}

export function hydrateConsultations(data) {
  return data && data.map((d) => hydrateConsultation(d));
}

export function hydrateConsultation(data) {
  return {
    consultationType: data && data.type,
  };
}

export const hydrateMeOutreachs = (data) => data.map((d) => hydrateMeOutreach(d));

export const hydrateMeOutreach = (data) => {
  const outrteachStartDate = moment(data.latest_outreach_start_time).format('YYYY-MM-DD');
  return {
    encounterUUID: data.encounter_uuid,
    userUUID: data.user_uuid,
    appointmentID: data.appointment_id,
    visitStatus: data.visit_status,
    latestEncounterUUID: data.latest_encounter_uuid,
    latestAppointmentScheduledAt: data.latest_appointment_scheduled_at,
    latestOutreachStartTime: data.latest_outreach_start_time,
    upcomingAppointment: data.upcoming_appointment,
    outreachDateDiffToToday: moment().diff(outrteachStartDate, 'days'),
    mLatestOutreachStartTime: moment(data.latest_outreach_start_time),
    encounterType: data.type,
    DOS: data.date_of_service,
    labDisplayName: data.lab_display_name,
    testDisplayName: data.test_display_name,
  };
};
