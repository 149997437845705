import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { showLoader, closeLoader } from 'ducks/ui';
import api from 'services/api/api';
import { AUTH0_CONFIG } from './Auth0Wrapper';
import authService from 'services/api/authService';
import { setStage } from 'ducks/registrationWizard';
import { CREATE_ACCOUNT } from 'services/wizardWorkflows';
import { logout } from '../ducks/auth/email';
import UpdateYourRecord from './pages/public/UpdateYourRecord/index';

const Auth0Login = function Auth0Login(props) {
  const { history, dispatch } = props;
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout: auth0Logout,
    user,
  } = useAuth0();
  const [error, setError] = useState(null);
  const [shouldEnterEmail, setShouldEnterEmail] = useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      async function getAccessToken() {
        try {
          dispatch(showLoader());
          const at = await getAccessTokenSilently({
            authorizationParams: {
              audience: AUTH0_CONFIG.audience,
            },
          });
          const token = `Bearer ${at}`;
          const res = await authService.checkLink({ token, type: 'auth0' });
          if (res && _.isEmpty(res.user)) {
            if (user.email) {
              setStage(CREATE_ACCOUNT);
              history.push('/signup/account');
            } else {
              setError('Please login with valid email or phone number');
              handleLogout();
            }
          } else if (res.user && (_.isNil(res.user.email) || res.user.email?.includes('noemail'))) {
            setShouldEnterEmail(true);
          } else {
            const url = authService.getRedirectionAction() || '/patient/home';
            authService.resetRedirectionAction();
            api.setToken(token);
            history.push(url);
          }
          dispatch(closeLoader());
        } catch (e) {
          console.error(e);
          if (e.message === 'Unknown or invalid refresh token.') {
            handleLogout();
          } else {
            setError(e);
          }
          dispatch(closeLoader());
        }
      }
      void getAccessToken();
    } else {
      try {
        const source = authService.getSource() || 'email';
        void loginWithRedirect({
          authorizationParams: {
            connection: source,
            redirect_uri: window.location.origin,
          },
        }).then(() => {
          authService.resetSource();
        });
      } catch (_) {}
    }
  }, [isAuthenticated, isLoading]);

  const handleLogout = () => {
    auth0Logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    }).then(() => {
      return dispatch(logout());
    });
  };

  if (shouldEnterEmail) {
    return <UpdateYourRecord />;
  }

  return error && <div>{error}</div>;
};

export default connect(null, (dispatch) => ({
  dispatch: dispatch,
}))(Auth0Login);
