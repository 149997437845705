// `process.env.*` values are injected by Webpack DefinePlugin
const FEATURE_FLAGS = {
  ENABLE_AUTH0_LOGIN: process.env.REACT_APP_FEATURE_FLAG_ENABLE_AUTH0_LOGIN,
  ENABLE_AUTH0_SMS_LOGIN: process.env.REACT_APP_FEATURE_FLAG_ENABLE_AUTH0_SMS_LOGIN,
};

/**
 * Test whether a feature has been enabled by configuration, or cookie, if allowed.
 * The logic checks for an environment variable named `REACT_APP_FEATURE_FLAG_${featureName}`,
 * or, if allowed, a cookie named `FEATURE_FLAG_${featureName}`.
 * @param {string} featureName The feature name, e.g. 'ENABLE_AUTH0_LOGIN'
 * @param {object} [options] Optional parameters
 * @param {boolean} [options.allowCookie] Whether a cookie can be used to enable the feature.
 * @returns {boolean} Whether the feature is enabled by config or cookie.
 */
export function isFeatureEnabled(featureName, options) {
  return (
    FEATURE_FLAGS[featureName] === true ||
    (options?.allowCookie && new RegExp(`FEATURE_FLAG_${featureName}=true`).test(document.cookie))
  );
}
