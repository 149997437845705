import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { isFeatureEnabled } from '../featureFlags';

export const AUTH0_CONFIG = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};

export const AUTH0_LOGIN_ENABLED = isFeatureEnabled('ENABLE_AUTH0_LOGIN', {
  allowCookie: true,
});

export const ENABLE_AUTH0_SMS_LOGIN = isFeatureEnabled('ENABLE_AUTH0_SMS_LOGIN', {
  allowCookie: true,
});

export const Auth0Wrapper = ({ children }) => (
  <Auth0Provider
    domain={AUTH0_CONFIG.domain}
    clientId={AUTH0_CONFIG.clientId}
    authorizationParams={{
      audience: AUTH0_CONFIG.audience,
      scope: 'openid profile email offline_access phone',
    }}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    {children}
  </Auth0Provider>
);
